@use '@angular/material' as mat;

@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/Proxima Nova Reg.otf');
}

@font-face {
  font-family: 'Proxima Nova Bold';
  src: url('../assets/fonts/Proxima Nova Bold.otf');
}

$o100w-typography: mat.m2-define-typography-config(
  $font-family: '"Proxima Nova", "Roboto", "Helvetica Neue", sans-serif',
  //  $headline-6: mat.define-typography-level($font-size: 32px, $font-weight: 800, $line-height: 24px, $letter-spacing: normal),
);
