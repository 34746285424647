@use '@angular/material' as mat;
@use 'theme' as *;
@use 'typography' as *;
@use 'vars' as *;

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($o100w-typography);`
/* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated*/
@include mat.all-component-typographies($o100w-typography);
/* TODO(mdc-migration): Remove legacy-core once all legacy components are migrated*/
@include mat.core();
/* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated*/
@include mat.all-component-themes($o100w-customized-theme);

html, body { height: 100%; }

body {
  --mat-form-field-container-text-size: 14px;

  margin: 0;
  background-color: mat.m2-get-color-from-palette($o100w-gray);
}


.filter form .mat-mdc-form-field-subscript-wrapper {
  height: 0;
}

.o100w-filler {
  flex: 1 1 auto;
}

.mat-toolbar .mdc-list-item__primary-text {
  display: inline-flex;
}

button.mdc-button {
  letter-spacing: normal;
}

mat-card {
  margin: 8px;

  form {
    margin-top: 0.5rem ;
  }

  .mat-mdc-card-actions,
  .o100w-card-actions-button-container {
    display: flex;
    column-gap: 0.75rem;
  }
}

mat-card form .mat-mdc-card-actions {
  padding-left: 0;
}


.mat-mdc-option {
  font-size: $dropdown-font-size;
}

.mat-mdc-card .mat-mdc-card-header {
  padding-top: 0.5rem;
}

.multi-line,
.multi-line-panel {
  height: unset;
  /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
  mat-option.mat-mdc-option {
    height: unset;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
  .mat-option-text.mat-option-text {
    white-space: normal;
    line-height: 1em;
  }

  .mat-mdc-option h4 {
    margin: 1rem 0;
    overflow: visible;
    line-height: initial;
    word-wrap: break-word;
    white-space: pre-wrap;
    font-size: 1rem;
  }

  .mat-mdc-option p {
    display: block;
    font-size: 0.9rem;
    opacity: 0.6;
    margin-left: 0.5rem;
  }
}

mat-form-field {
  span[matSuffix] {
    padding-right: 4px;
  }
}

// Global styles for the custom autocomplete component (to prevent using ng-deep)
.fl-autocomplete-form-field.grid-filter-field {
  .mat-mdc-form-field-infix {
    padding-right: 36px;
  }

  .mat-mdc-form-field-icon-suffix {
    width: 0;
    right: 36px;

    button {
      min-width: 1rem;

      mat-icon {
        margin: 0;
      }
    }
  }
}

.o100w-info-text {
  border-radius: 0.5rem;
  padding: 0.75rem;

  @mixin setColor($palette) {
    border: 1px solid mat.m2-get-color-from-palette($palette, "darker");
    background: mat.m2-get-color-from-palette($palette, "lighter");
    color: mat.m2-get-color-from-palette($palette, "lighter-contrast");
  }

  form &:not(:last-child) {
    margin-bottom: 1.34375em; // Match padding-bottom of an mat-form-field (an example in quest-edit)
  }

  &--error {
    @include setColor($o100w-red);
  }

  &--warn {
    @include setColor($o100w-yellow);
  }

  &--info {
    @include setColor($o100w-blue);
  }

}

.o100w-clickable {
  cursor: pointer;
}

.cdk-overlay-backdrop {

  &.o100w-overlay-light-backdrop {
    background-color: mat.m2-get-color-from-palette($o100w-gray, "darker");
  }

  &.o100w-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
    opacity: .25;
  }
}

// Drop down menu panel
.mat-mdc-menu-panel {
  .svg-inline--fa {
    vertical-align: baseline;
  }
}

/* Change the border color */
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: $legacy-input-border-color !important;
}

.mat-mdc-snack-bar-container {
  @mixin setColor($palette) {
    .mdc-snackbar__surface {
      background: mat.m2-get-color-from-palette($palette);
      color: mat.m2-get-color-from-palette($palette, "default-contrast");

      .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
        color: mat.m2-get-color-from-palette($palette, "default-contrast");
      }
    }
  }

  &.info-snackbar {
    @include setColor($o100w-blue);
  }

  &.success-snackbar {
    @include setColor($o100w-green);
  }

  &.warning-snackbar {
    @include setColor($o100w-yellow);
  }

  &.error-snackbar {
    @include setColor($o100w-red);
  }

}
